export const Register = "/register";
export const Login = "/login";
export const Page404 = "/404";
export const Page500 = "/505";
export const Dashboard = "/dashboard";

export const UserList = "/user/list";
export const ProjectList = "/project/list";
export const ProjectRequest = "/project/request";
export const AddProject = "/project/add";
export const AddProjectId = "/project/add/:id";
export const EditProject = "/project/edit";
export const EditProjectId = "/project/edit/:id";

export const ViewReport = "/audit/view";
export const ViewReportId = "/audit/view/:id";

export const AddAuditIssue = "/audit/issue/add";
export const AddAuditIssueId = "/audit/issue/add/:id/:auditId";

export const EditAuditIssue = "/audit/issue/edit";
export const EditAuditIssueId = "/audit/issue/edit/:id/:auditId";

export const Report = "/audit/report";
export const ReportId = "/audit/report/:id";

export const ProjectAuditAiList = "/project/auditai/list";

export const ViewAiFindings = "/audit/findings";
export const ViewAiFindingsId = "/audit/findings/:id";
