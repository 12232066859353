// * React Hooks
import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// * Routes
import * as URL from "./Routes";
import ProtectedRoute from "src/helpers/ProtectedRoute";

// * Pages
const Login = lazy(() => import("../views/pages/Login/Login"));
const Register = lazy(() => import("../views/pages/register/Register"));
const Page404 = lazy(() => import("../views/pages/page404/Page404"));
const Page505 = lazy(() => import("../views/pages/page500/Page500"));
const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));

const UserList = lazy(() => import("../views/pages/User/List"));

const ProjectList = lazy(() => import("../views/pages/Project/List"));
const ProjectRequestList = lazy(() => import("../views/pages/Request/List"));

const AddProject = lazy(() => import("../views/pages/Project/Add/AddProject"));
const EditProject = lazy(() =>
  import("../views/pages/Project/Edit/EditProject")
);
const ReportView = lazy(() =>
  import("../views/pages/Project/Findings/Findings")
);

const AddIssue = lazy(() => import("../views/pages/Project/AddIssue/AddIssue"));
const EditIssue = lazy(() =>
  import("../views/pages/Project/EditIssue/EditIssue")
);

const Reporting = lazy(() => import("../views/pages/Project/Report/Report"));

const ProjectAuditAiList = lazy(() => import("../views/pages/AuditAi/List"));

const AuditAiFinding = lazy(() =>
  import("../views/pages/AuditAi/Findings/Findings")
);

// * Page Layout Component
const Layout = lazy(() => import("../layout/Layout"));

// * Loader
const Loader = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

export default function Router() {
  const Navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") Navigate("/login");
  });
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={URL.Login}
          element={
            <ProtectedRoute accessBy="non-authenticated">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.Dashboard}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Dashboard />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.UserList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<UserList />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.ProjectList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ProjectList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddProjectId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddProject />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditProjectId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditProject />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ProjectRequest}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ProjectRequestList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewReportId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ReportView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddAuditIssueId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddIssue />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditAuditIssueId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditIssue />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ReportId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Reporting />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ProjectAuditAiList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ProjectAuditAiList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewAiFindingsId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AuditAiFinding />} />
            </ProtectedRoute>
          }
        />

        <Route path={URL.Register} element={<Register />} />
        <Route path={URL.Page500} element={<Page505 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
}
